import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface PasswordInputProps {
  value: string;
  onChange: (key: string) => void;
  onSubmit: (event: React.KeyboardEvent) => void;
  disabled: boolean;
}

const PasswordInput = ({
  value,
  onChange,
  onSubmit,
  disabled,
}: PasswordInputProps): React.ReactElement => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };

  const handleKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      onSubmit(event);
    }
  };

  return (
    <FormControl required fullWidth variant="filled">
      <InputLabel htmlFor="password-input">Password</InputLabel>
      <FilledInput
        id="password-input"
        type={showPassword ? 'text' : 'password'}
        value={value}
        disabled={disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          onChange((event.target as HTMLInputElement).value);
        }}
        onKeyDown={handleKeyPress}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={(): void => setShowPassword(!showPassword)}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default PasswordInput;
