import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';

interface EmailInputProps {
  value: string;
  onChange: (key: string) => void;
  disabled: boolean;
}

const EmailInput = ({ value, onChange, disabled }: EmailInputProps): React.ReactElement => {
  return (
    <FormControl required fullWidth variant="filled">
      <InputLabel htmlFor="email-input">Email</InputLabel>
      <FilledInput
        id="email-input"
        type="email"
        value={value}
        disabled={disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          onChange((event.target as HTMLInputElement).value);
        }}
      />
    </FormControl>
  );
};

export default EmailInput;
