import * as React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { navigate } from 'gatsby';

import SEO from '../components/Seo';
import Title from '../components/Title';
import PasswordInput from '../components/PasswordInput';
import Loader from '../components/Loader';
import EmailInput from '../components/EmailInput';
import { setCredentialsToCookie, submitLogin, isLoggedIn } from '../lib/auth';

const Login = (): React.ReactNode => {
  const [loading, setLoading] = React.useState(false);
  const [loadingIsLoggedIn, setLoadingIsLoggedIn] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  React.useEffect(() => {
    isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        navigate('/add');
      } else {
        setLoadingIsLoggedIn(false);
      }
    });
  }, []);

  const handleSubmit = (event: React.KeyboardEvent | React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    setError(null);
    setLoading(true);

    submitLogin(email, password)
      .then(() => {
        setCredentialsToCookie(email, password);

        if (typeof window !== 'undefined') {
          navigate('/add');
        }
      })
      .catch((e) => {
        if (e?.response) {
          if (e.response.status === 401) {
            setError("Sorry, that email or password didn't work.");
          } else {
            setError(e.response.data || 'Something went wrong and there is no data specified');
          }

          console.error('e.response.status: ', e.response.status);
          console.error('e.response: ', e.response);
        } else if (e?.request) {
          setError(
            e.request.responseText ||
              e.request.statusText ||
              'Something went wrong with the network call. This is not backend fault',
          );

          console.error('e.request: ', e.request);
        } else {
          setError(e.message);

          console.error('e.message: ', e.message);
        }

        setLoading(false);
      });
  };

  const handleErrorClose = (): void => {
    setError(null);
  };

  if (loadingIsLoggedIn) {
    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} style={{ paddingTop: '64px' }}>
          <Loader />
        </Grid>
      </Grid>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4} direction="column" alignItems="center">
        <SEO title="Login" />

        <Grid item xs={12}>
          <Title />
        </Grid>

        <Grid container item xs={12} sm={6} md={5}>
          <EmailInput disabled={loading} value={email} onChange={setEmail} />
        </Grid>

        <Grid container item xs={12} sm={6} md={5}>
          <PasswordInput
            disabled={loading}
            value={password}
            onChange={setPassword}
            onSubmit={handleSubmit}
          />
        </Grid>

        <Grid container item xs={12} sm={6} md={5}>
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            {loading ? <Loader /> : 'Log in'}
          </Button>
        </Grid>

        <Snackbar open={!!error}>
          <Alert onClose={handleErrorClose} elevation={6} variant="filled" severity="error">
            {error}
          </Alert>
        </Snackbar>
      </Grid>
    </form>
  );
};
export default Login;
